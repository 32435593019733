import AWS from 'aws-sdk';
import { getBestMatch } from '../utils/fuzzyMatch';
import chatbotData from '../data/chatbotData.json'; // Import JSON data

// Configure AWS SDK
AWS.config.update({
  region: 'us-east-1',  // Adjust the region as necessary
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

/**
 * Load all stored questions from both DynamoDB and JSON.
 */
export const loadAllQuestions = async () => {
    const params = {
        TableName: 'ChatbotIntents',
        ProjectionExpression: 'question' // Fetch only the `question` attribute
    };

    try {
        // Fetch questions from DynamoDB
        const data = await dynamoDB.scan(params).promise();

        const dynamoQuestions = data.Items.map(item => item.question);
        // Merge with JSON questions
        const jsonQuestions = Object.keys(chatbotData);
        // Combine both sources, avoiding duplicates
        const allQuestions = [...new Set([...dynamoQuestions, ...jsonQuestions])];
        return allQuestions;
    } catch (error) {
        console.error("❌ Error loading questions from DynamoDB:", error);
        return Object.keys(chatbotData); // Fallback to JSON questions if DynamoDB fails
    }
};

/**
 * Fetch chatbot response from DynamoDB first, then fallback to JSON if not found.
 */
export const getChatbotIntent = async (userQuestion, storedQuestions) => {
    
    const matchedQuestion = getBestMatch(userQuestion, storedQuestions);

    if (!matchedQuestion) {
        console.warn("⚠️ No match found for user question.");
        return "I don't have an answer for that yet! Hold tight! 🚧 Kunal’s still fine-tuning me.🔧 Try asking something else.";
    }

    // Query DynamoDB first
    const params = {
        TableName: 'ChatbotIntents', // DynamoDB table name
        IndexName: 'question-index',  // Ensure this index exists!
        KeyConditionExpression: '#question = :question',
        ExpressionAttributeNames: { '#question': 'question' },
        ExpressionAttributeValues: { ':question': matchedQuestion }
    };

    try {
        const data = await dynamoDB.query(params).promise();
        if (data.Items && data.Items.length > 0) {
            return data.Items[0].answer; // ✅ Return actual answer from DynamoDB
        }
    } catch (error) {
        console.error("❌ Error fetching data from DynamoDB:", error);
    }

    // ✅ Fallback to JSON file
    return chatbotData[matchedQuestion] || "I don't have an answer for that yet!";
};
