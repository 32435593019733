import React, { useState } from 'react';
import './Home.css';
import ChatButton from '../components/Chat/ChatButton';
import ChatBox from '../components/Chat/ChatBox';
import Navbar from '../components/Navbar/Navbar'; 

const Home = () => {
    const [showChat, setShowChat] = useState(false);
    const [showHelloMessage, setShowHelloMessage] = useState(true);

    return (
        <div className="home-container">
            <header className="navbar-section">
                 <Navbar />
             </header>
            {/* Top Background Image Section */}
           {/* <div 
                className="background-top"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + ''})`,
                }}
            ></div> *//*uncomment this and css thing in home container to add*/}
            
             {/* Content Section */}
             <div className="background-bottom">
                <div className="intro-section">
                    {/* Avatar and Chat Wrapper */}
                    <div className="intro-avatar">
                        <img src="/avatar.png" alt="Kunal Kapoor Full-body Avatar" className="avatar-full-body" />
                       
                        <div className="combined-chat-container">
                            {/* Conditional Rendering of Hello Message and Chat Button */}
                            {showHelloMessage && (
                                <>
                                    <div className="hello-message">
                                        <p>Hello</p>
                                    </div>
                                    <ChatButton 
                                        onClick={() => {
                                            setShowChat(true);
                                            setShowHelloMessage(false); // Hide the initial Hello message
                                        }}

                                    />
                                </>
                            )}
                        
                        </div>
                                
                        <div className="combined-chatbot-container">
                                {/* ChatBox - Conditional Rendering */}
                                {showChat && (
                                    <>
                                        {/* Close Button - Fixed on Top-Right */}
                                        <button 
                                            className="close-button"
                                            onClick={() => {
                                                setShowChat(false);
                                                setShowHelloMessage(true); // Show Hello message again when chat is closed
                                            }}
                                        >
                                            ✖
                                        </button>

                                        <ChatBox
                                            isVisible={showChat}
                                            onSubmit={(message) => {
                                                console.log("Message sent:", message);
                                            }}
                                            initialMessage="Hello! "
                                        />
                                    </>
                                )}
                            </div>

                    </div>

                    

                    {/* Introductory Text */}
                    <div className="intro-text">
    <h1>Hi, I'm Kunal Kapoor</h1>
    <h2>Turning ideas into scalable solutions</h2>
    <p>
    Software developer who loves building innovative, scalable, 
    and user-focused solutions. Whether it’s creating smooth front-end designs or strong,
    reliable back-end systems, I specialize in delivering high-performance software that helps 
    businesses thrive and keep up in today’s fast-moving tech world.
    </p>
    {/* Social Icons */}
    <div className="social-icons">
        <a href="https://github.com/Kunal7636" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
        </a>
        <a href="https://linkedin.com/in/kunalkapoor03" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://medium.com/@98kunalkapoor/about" target="_blank" rel="noopener noreferrer" className="icon-button">
    <i className="fab fa-medium"></i> {/* Font Awesome Medium Icon */}
  </a>
    </div>

    <div className="profile-stats">
        <div className="stat-item">
            <h2>4+</h2>
            <p>Years Of Experience</p>
        </div>
        <div className="stat-item">
            <h2>22+</h2>
            <p>Git Projects</p>
        </div>
        <div className="stat-item">
            <h2>25+</h2>
            <p>Certifications</p>
        </div>
    </div>
</div>

                </div>
                <div id="about-section" className="about-me-section">
                <h2 class="section-title">About Me</h2>
    <div className="section-content">
        <p>
            Hey there! I'm Kunal Kapoor, a software developer passionate about crafting web and mobile applications that solve real-world problems. 
            I specialize in full-stack development, blending technologies like Java, Python, Flutter, and React to build intuitive and scalable solutions.
        </p>
        <p>
            As a developer at Alpha Tickets Ltd, I architect and implement solutions for <a href="https://theticketvault.com" target="_blank" rel="noopener noreferrer">TheTicketVault.com</a>, 
            driving growth by optimizing databases, streamlining deployments, and creating seamless user experiences.  
        </p>
        <p>
            Whether it's integrating cloud infrastructure with AWS, building video annotation tools with Flutter, or fine-tuning CI/CD pipelines, I thrive on creating robust and efficient systems. 
            I'm always exploring new technologies and leveraging innovative solutions to push projects to the next level.  
        </p>
        <p>
            Let's build something great together!
        </p>
    </div>
</div>


                {/* What I Bring Section */}
                <div className="what-i-bring-section"  id="ab">
                <h2 className="section-title">Core Strengths</h2>
                <div className="what-i-bring">
                    <div className="strength-card" >
                        <h3>Scalable Solutions Architect</h3>
                        <p>I design systems that grow effortlessly with demand. 
                            From building the backend for a supply chain management system using Node.js and MySQL to architecting a forum capable of handling 5,000+ users with Django, I focus on creating efficient, scalable solutions. By optimizing database schemas and refining backend logic, I ensure smooth performance and seamless user experiences, even under heavy load.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Performance Optimization Expert</h3>
                        <p>I love creating tools that work under the hood. I built a lightweight custom shell in C, adding features like piping, redirection, and background processes to make command execution faster and smoother. On the web side, I developed an Express.js app with modular route handlers to optimize API performance, ensuring it could handle multiple requests at once. I also integrated JWT authentication to keep data secure without slowing things down.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Strategic Problem Solver</h3>
                        <p>I drive both code and strategy. As founder of TheTicketVault.com, I manage product design, backend development, and growth. I led the EcoTech Connect team to build and optimize a high-traffic Django forum with CI/CD pipelines and rigorous testing.
                            From full-stack development (Node.js, Django) to system-level work (C, MySQL), I refine workflows, solve bottlenecks, and lead teams to build scalable solutions.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Versatile Technologist</h3>
                        <p>I thrive in diverse tech environments, adapting quickly to different stacks and project needs. During COVID-19, I built a full-stack food ordering platform using PHP, enabling small vendors to sell online with secure payment integration. From developing frontend interfaces with HTML/CSS to architecting backend systems with Node.js, Django, and C, I handle projects across web, backend, and system levels. Whether it's crafting APIs, optimizing databases, or building system tools, I enjoy bringing ideas to life through code.</p>
                    </div>
                </div>
                </div>
    
    <div className="experience-section" id="experience">
    <h2 className="experience-title">Experience</h2>
    <div className="experience-timeline">
        
        <div className="timeline-entry">
            <div className="timeline-date">May 2023 – Present</div>
            <div className="timeline-content">
            <h3>Software Developer <span>·  <a href="https://theticketvault.com" target="_blank" rel="noopener noreferrer"> Alpha Tickets Ltd ↗</a> </span>
            </h3>
            <p>
                        • Contributed to the migration of the website’s monolithic backend to a Node.js microservices architecture using Express.js and MongoDB, enhancing scalability and deployment efficiency.<br />
                        • Built and tested RESTful APIs with Express.js and MongoDB, enabling seamless data handling for features like event management and ticket processing.<br />
                        • Developed responsive frontend components with React.js, integrating tax invoice generation, order history, and account management to enhance user experience.<br />
                        • Streamlined CI/CD pipelines using Docker and GitHub Actions, cutting deployment times by 40% and increasing release stability.<br />
                        • Integrated PayPal to support multiple payment gateways, reducing errors and enabling international transactions.<br />
                        • Enhanced the My Listings feature by building a React.js dashboard connected to Node.js and MongoDB, providing organizers with tools to manage events, track sales, and monitor earnings.
            </p>
                <div className="tech-stack">
                    <span>Java</span>
                    <span>Spring Boot</span>
                    <span>MongoDB</span>
                    <span>REST</span>
                </div>
            </div>
        </div>

        <div className="timeline-entry">
            <div className="timeline-date">April 2021 – Sep 2022</div>
            <div className="timeline-content">
                <h3>Software Developer <span>· <a href="https://www.majidalfuttaim.com/" target="_blank" rel="noopener noreferrer">Majid Al Futtaim Hypermarkets Pvt Ltd ↗</a></span></h3>
                <p>
                    • Took responsibility for developing Android (Espresso, Java) and iOS (XCTest) test automation frameworks, cutting manual testing and speeding up releases by faster bug detection.<br />
                    • Improved the product listing page (PLP) by developing search algorithms using Elasticsearch and Spring Boot (Java), boosting search accuracy and speed, which led to a 12% increase in conversions.<br />
                    • Developed 20+ RESTful APIs in a microservices setup, improving data flow across the homepage, cart, and product pages, reducing API response times.<br />
                    • Conducted knowledge-sharing sessions with team members, explaining product architecture and microservices workflows.
                </p>
                <div className="tech-stack">
                    <span>Java</span>
                    <span>SQL</span>
                    <span>Espresso</span>
                </div>
            </div>
        </div>

        <div className="timeline-entry">
            <div className="timeline-date">Oct 2020 – April 2021</div>
            <div className="timeline-content">
                <h3>Software Developer Co-Op <span>· <a href="https://www.majidalfuttaim.com/" target="_blank" rel="noopener noreferrer">Majid Al Futtaim Hypermarkets Pvt Ltd ↗</a></span></h3>
                <p>
                    • Contributed to product listing page (PLP) and Wishlist feature enhancements, gaining full-cycle exposure to application development and deployment using Java (Spring Boot) and React.<br />
                    • Took ownership of unit and integration test cases for a mini-carousel of related products using JUnit and Mockito, driving an increase in average order value (AOV) and boosting sales through cross-selling.<br />
                    • Collaborated with the product display and API teams to optimize real-time inventory (livestock) updates in cart interactions using Spring Boot and MongoDB, improving accuracy and reducing cart errors by 6%.
                </p>
                <div className="tech-stack">
                    <span>Java</span>
                    <span>MongoDB</span>
                    <span>Espresso</span>
                </div>
            </div>
        </div>

    </div>
</div>

<div className="projects-section" id="projects">
    <h2 className="projects-title">Projects</h2>
    <div className="projects-timeline">

        <div className="timeline-entry">
            <div className="timeline-date"> 2024</div>
            <div className="timeline-content">
                <h3>
                    <span>  <a href="https://github.com/Kunal7636/event-management" target="_blank" rel="noopener noreferrer">Volunteer Hub  ↗</a></span>
                </h3>
                <p>
                    • Built a scalable microservices platform for managing volunteer roles and applications, boosting performance by 15% and handling 30% more user activity.<br />
                    • Architected the system with Java, Spring Boot, and MongoDB, optimizing response times by 25%.<br />
                    • Implemented secure RESTful APIs with Spring Security for authentication and authorization.<br />
                    • Utilized Swagger for comprehensive API documentation and JUnit for rigorous unit testing.<br />
                    • Created Docker images for microservices, cutting setup time by 40%.
                </p>
            </div>
        </div>

        <div className="timeline-entry">
            <div className="timeline-date"> 2024</div>
            <div className="timeline-content">
                <h3>
                    <span>  <a href="https://github.com/RuohanSong/EcoTech" target="_blank" rel="noopener noreferrer">EcoTech Connect – Forum Website ↗</a></span>
                </h3>
                <p>
                    • Developed backend architecture with Django and Python, enhancing article management and search, boosting content efficiency by 30% and user engagement by 25%.<br />
                    • Implemented secure file handling and user authentication with Django, reducing security incidents by 40% and increasing user sign-ups by 20%.<br />
                    • Resolved bugs and improved styling with HTML and CSS, decreasing user-reported issues.<br />
                    • Conducted load testing, ensuring the system efficiently handled over 5,000 concurrent users without issues.
                </p>
            </div>
        </div>

        <div className="timeline-entry">
            <div className="timeline-date">2024</div>
            <div className="timeline-content">
                <h3>
                    <span>  <a href="https://github.com/Kunal7636/Shell" target="_blank" rel="noopener noreferrer">Shell  ↗</a></span>
                </h3>
                <p>
                    • Developed a custom shell in C that supports user commands, piping, redirection, and conditional execution.<br />
                    • Integrated features for sequential command execution, background processing, and foreground processing.
                </p>
            </div>
        </div>

    </div>
    {/* Full Project Archive Link */}
    <div className="project-archive-link">
        <a href="https://github.com/Kunal7636?tab=repositories" target="_blank" rel="noopener noreferrer">
            View Full Project Archive ↗
        </a>
    </div>
</div>


<div class="skills-section" id = "skills">
    <h2 class="section-title">Technical Skills</h2>
    <div class="skills-grid">
        <div class="skill-card">
            <img src="/JavaScript-logo.png" alt="JavaScript"/>
            <h3>JavaScript</h3>
            <p>Proficient in syntax and adept at handling asynchronous programming.</p>
        </div>

        <div class="skill-card">
            <img src="/python.png" alt="Python"/>
            <h3>Python</h3>
            <p>Advanced in Python, with experience in web development.</p>
        </div>

        <div class="skill-card">
            <img src="/java.png" alt="Java"/>
            <h3>Java</h3>
            <p>Proficient in Java, with experience in developing web applications.</p>
        </div>

        <div class="skill-card">
            <img src="/logo192.png" alt="ReactJS"/>
            <h3>ReactJS</h3>
            <p>Proficient in ReactJS for developing interactive and responsive user interfaces.</p>
        </div>

        <div class="skill-card">
            <img src="/nodejs.svg" alt="NodeJS"/>
            <h3>NodeJS</h3>
            <p>Proficient in NodeJS, with experience in server-side development.</p>
        </div>

        <div class="skill-card">
            <img src="/aws.png" alt="AWS"/>
            <h3>AWS</h3>
            <p>Experienced in deploying and managing scalable applications using AWS services like EC2, S3, Lambda, and RDS.</p>
        </div>

        <div class="skill-card">
            <img src="/database.jpeg" alt="Databases"/>
            <h3>Databases</h3>
            <p>Experienced in MongoDB, MySQL, and DynamoDB.</p>
        </div>

        <div class="skill-card">
            <img src="/microservice.png" alt="Microservices"/>
            <h3>Microservices</h3>
            <p>Proficient in designing and deploying microservices architectures for scalable and resilient applications.</p>
        </div>

        <div class="skill-card">
            <img src="/arch.png" alt="Design Architecture"/>
            <h3>Design Architecture</h3>
            <p>Experienced in designing and building software architectures for responsive and scalable applications.</p>
        </div>
    </div>
</div>

<div className="education-section" id="education">
    <h2 className="education-title">Education</h2>
    <div className="education-grid">

        <div className="education-card">
            <h3>Master of Applied Computing</h3>
            <p>University of Windsor</p>
            <p>Windsor, Canada</p>
            <p>Sep 2023 – Dec 2024</p>
            <p>Focus: Advanced Computing, Cloud Infrastructure</p>
        </div>

        <div className="education-card">
            <h3>Post-Graduate Certificate in Back-End and Blockchain Development</h3>
            <p>York University</p>
            <p>Toronto, Canada</p>
            <p> Sep 2022 – Apr 2023</p>
            <p>Focus: Blockchain, Smart Contracts, API Development</p>
        </div>

        <div className="education-card">
            <h3>Bachelor of Computer Science and Engineering</h3>
            <p>Chitkara University</p>
            <p>Punjab, India</p>
            <p>June 2017 – June 2021</p>
            <p>Focus: Software Development, Data Structures, AI</p>
        </div>

    </div>
</div>


            </div>
        </div>
    );
};


export default Home;
